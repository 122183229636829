<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="提现单号：">
        <el-input v-model="searchForm.applysn" size="small"></el-input>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader
          v-model="searchForm.city_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          placeholder="请选择或搜索"
          clearable
          filterable
          :options="areaList"
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="账单状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="待结算" :value="1"></el-option>
          <el-option label="已结算" :value="2"></el-option>
          <el-option label="已驳回" :value="-1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker
          v-model="searchForm.create_time"
          value-format="timestamp"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="applysn" label="提现单号" width="180" align="center"></el-table-column>
      <el-table-column prop="create_time" label="申请时间" width="150" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="member_info" label="用户信息" width="200" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.user_info.nickname" :avatar="row.member_info.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="member_info.name" label="店铺/师傅名称" align="center"></el-table-column>
      <el-table-column prop="city_name" label="区域" align="center"></el-table-column>
      <el-table-column prop="applyprice" label="提现金额" align="center"></el-table-column>
      <el-table-column prop="member_info.withdraw_rate" label="服务费率（微信\支付宝费率）" align="center">
        <template v-slot="{ row }">{{ row.member_info.withdraw_rate }}%</template>
      </el-table-column>
      <el-table-column prop="member_info.withdraw_rate" label="服务费用" align="center">
        <template v-slot="{ row }">
          {{ parseFloat(row.applyprice - row.accountprice).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column prop="accountprice" label="应结费用" align="center"></el-table-column>
      <el-table-column prop="status" label="账单状态" align="center">
        <template v-slot="{ row }">
          <span :style="{ color: row.status == 0 ? '#f56c6c' : row.status == 1 ? '#f56c6c' : row.status == 2 ? '#409EFF' : '#e6a23c' }">
            {{ row.status == 0 ? '待审核' : row.status == 1 ? '待结算' : row.status == 2 ? '已结算' : '已驳回' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="row.status == 0" @click="agree(row)" type="text" size="small">同意</el-button>
          <el-button v-if="row.status == 1" @click="remit(row)" type="text" size="small">打款</el-button>
          <el-button v-if="row.status == 0" @click="remark(row)" type="text" size="small">驳回</el-button>
          <el-button @click="seeInfo(row)" type="text" size="small">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="审核" :visible.sync="dislogaudit" width="600px">
      <el-form class="auditForm" label-width="100px" ref="form" :model="auditForm" :rules="rules">
        <template v-if="auditForm.status == 2">
          <el-form-item label="持卡人姓名：">{{ auditForm.bank_info.bank_name }}</el-form-item>
          <el-form-item label="银行卡号：">{{ auditForm.bank_info.bank_card }}</el-form-item>
          <el-form-item label="银行信息：">{{ auditForm.bank_info.bank_info }}</el-form-item>
          <el-form-item label="打款金额：" prop="remit_amount">
            {{ auditForm.accountprice }}
          </el-form-item>
          <el-form-item label="打款备注：" prop="proof_remark">
            <el-input v-model="auditForm.proof_remark" type="textarea" :rows="3"></el-input>
          </el-form-item>
          <el-form-item label="打款凭证：" prop="proof">
            <ReadyUploadSource @getSource="val => (auditForm.proof = val.path)" :isManyMax="1" :path="auditForm.proof" @removeThis="() => (auditForm.voucher = '')"></ReadyUploadSource>
          </el-form-item>
        </template>
        <el-form-item v-if="auditForm.status == -1" label="驳回原因：" prop="remark">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="auditForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogaudit = !1">取 消</el-button>
        <el-button type="primary" @click="handleAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dislogauditinfo" width="600px">
      <el-form class="auditFormInfo" label-width="100px">
        <el-form-item label="审核结果：">{{ currentInfo.status == 0 ? '待审核' : currentInfo.status == 1 ? '待结算' : currentInfo.status == 2 ? '已结算' : '已驳回' }}</el-form-item>
        <template v-if="currentInfo.status == 2">
          <el-form-item label="打款备注：">{{ currentInfo.proof_remark }}</el-form-item>
          <el-form-item label="打款凭证：">
            <el-image style="width: 100px; height: 70px" :src="currentInfo.proof" :preview-src-list="[currentInfo.proof]"></el-image>
          </el-form-item>
        </template>
        <el-form-item v-if="currentInfo.status == -1" label="驳回原因：">{{ currentInfo.remark }}</el-form-item>
        <el-form-item label="持卡人姓名：">{{ currentInfo.bank_info.bank_name }}</el-form-item>
        <el-form-item label="银行卡号：">{{ currentInfo.bank_info.bank_card }}</el-form-item>
        <el-form-item label="银行信息：">{{ currentInfo.bank_info.bank_info }}</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogauditinfo = !1">取 消</el-button>
        <el-button type="primary" @click="dislogauditinfo">确 定</el-button>
      </span>
    </el-dialog>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import userInfo from '@/components/userInfo';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    ReadyUploadSource,
    userInfo,
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        applysn: '',
        city_id: [],
        status: '',
        create_time: '',
      },
      dislogaudit: !1,
      dislogauditinfo: !1,
      list: [],
      areaList: [],
      total_number: 0,
      id: '',
      currentInfo: {
        bank_info: {},
      },
      auditForm: {
        status: 2,
        remark: '',
        proof_remark: '',
        proof: '',
        accountprice: '',
        bank_info: {},
      },
      rules: {
        remark: { required: true, message: '请填写驳回原因', trigger: 'blur' },
        proof_remark: { required: true, message: '请填写打款备注', trigger: 'blur' },
        proof: { required: true, message: '请添加打款凭证', trigger: 'blur' },
      },
    };
  },
  created() {
    this.getList();
    this.getArea();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    }, 
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        applysn: '',
        city_id: [],
        status: '',
        create_time: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    seeInfo(row) {
      this.currentInfo = row;
      this.dislogauditinfo = !0;
    },
    //同意
    agree(row) {
      this.$confirm('请确认是否同意？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.samecity.serviceOrderCheck, {
            id: row.id,
            status: 1,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('已同意');
              row.status = 1;
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    //打款
    remit(row) {
      this.id = row.id;
      this.dislogaudit = !0;
      this.auditForm.status = 2;
      this.auditForm.accountprice = row.accountprice;
      this.auditForm.bank_info = row.bank_info;
    },
    remark(row) {
      this.id = row.id;
      this.dislogaudit = !0;
      this.auditForm.status = -1;
    },
    handleAudit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            id: this.id,
            status: this.auditForm.status,
          };
          if (data.status == 2) {
            delete data.status;
            data.proof = this.auditForm.proof;
            data.proof_remark = this.auditForm.proof_remark;
            this.$axios.post(this.$api.samecity.serviceOrderPayment, data).then(res => {
              if (res.code == 0) {
                this.$message.success('已打款');
                this.dislogaudit = !1;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
          if (data.status == -1) {
            data.remark = this.auditForm.remark;
            this.$axios.post(this.$api.samecity.serviceOrderCheck, data).then(res => {
              if (res.code == 0) {
                this.$message.success('已驳回');
                this.dislogaudit = !1;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.city_id.length) {
        searchForm.city_id.length > 1 ? (searchForm.city_id = searchForm.city_id[1]) : (searchForm.city_id = searchForm.city_id[0]);
      } else {
        delete searchForm.city_id;
      }
      if (searchForm.create_time.length) {
        for (let i in searchForm.create_time) {
          searchForm.create_time[i] = searchForm.create_time[i] / 1000;
        }
      } else {
        delete searchForm.create_time;
      }
      if (typeof searchForm.status == 'string') delete searchForm.status;
      if (!searchForm.applysn) delete searchForm.applysn;
      this.$axios.post(this.$api.samecity.applyCashList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
          
        }
      });
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            for (let y in list[i]._child) delete list[i]._child[y]._child;
          }
          this.areaList = list;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
